import { useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  DataGridPro,
  GridCallbackDetails,
  MuiEvent,
  GridColumnHeaderParams,
  useGridApiRef,
  GridRowOrderChangeParams,
  GridColumnOrderChangeParams,
  GridEventListener,
} from '@mui/x-data-grid-pro';
import { Box } from '@mui/material';

import { DraggableGridBlockWrapper } from '../../DraggableGridBlock/DraggableGridBlockWrapper';
import useBlockState from '../useBlockState';
import { SidePanelProviderContext } from 'components/editor/SidePanel/content/SidePanelModelsProvider';
import { SelectionContext } from '../../SelectedBlockInfoProvider';

import { useApplyRulesToColumn } from './useApplyRulesToColumn';
import { useTableColumnManipulation } from '../../../SidePanel/content/TableSettings/ColumnSettings/useTableColumnManipulation';
import { RootState, useAppDispatch } from '../../../grid/reduxStore/Store';
import { selectContentTable } from '../../../grid/reduxStore/editorSlice';
import { setToggledDesignSettingModelType } from '../../../grid/reduxStore/blockStyleSettingsSlice';
import { useBlockContentChangedHandler } from '../../../hooks/UseBlockContentChangedHandler';
import { useTableManipulation } from './useTableManipulation';
import { dragDropManager } from '../../../dndContext';
import { TableType } from '../../../grid/reduxStore/table.types';
import { useSectionData } from '../../../Sections/useSectionContext';
import { setActiveTableSettingsPanel } from '../../../grid/reduxStore/blockStyleSettingsSlice';
import { calculateTotal } from './tableUtils/calculateSubTotalsPerRow';
import { useBlockDimensionChangedHandlerWithoutUndoRedo } from '../../../hooks/UseBlockDimensionChangedHandler';
import { getRowClassName } from '../../../../../muiTheme/dataGridUtils';
import { useTableCellManipulation } from './useCellManipulation';
import { PricingTableTotalsFooter } from './PricingTableTotalsFooter';
import { getPricingTotalsAndTextRowsSegregated } from './helper';
import useTableRowSettingsPanel from '../../../SidePanel/content/TableSettings/RowSettings/useTableRowSettingsPanel';
import { TableSettingsTypes } from '../../../SidePanel/content/TableSettings/types';

export interface TableBlockProps {
  loading: boolean;
  documentId: string;
  blockId: string;
  handleMouseDown?: (blockId) => void;
  setIsDragManagerSetup?: (state: boolean) => void;
  testId?: string;
}

export function TableBlock({ loading, blockId, setIsDragManagerSetup, testId }: TableBlockProps) {
  const { handleOnMouseDown, isEditMode } = useBlockState(blockId);
  const [isSelectModel, setIsSelectModel] = useState(false);
  const { toggledTableSettingsPanel, setToggledTableSettingsPanel } = useContext(SidePanelProviderContext);
  const { selectedBlockIdByWrapper } = useContext(SelectionContext);
  const { sectionId } = useSectionData();
  const { onCellEdited, getCellClassName } = useTableCellManipulation(blockId, sectionId, toggledTableSettingsPanel);

  const dispatch = useAppDispatch();
  const tableData = useSelector((state: RootState) => selectContentTable(state, sectionId, blockId)) || undefined;
  const allTextTableRows = tableData?.rows;
  const allTextTableColumns = tableData?.columns || [];

  const wasEditModeChangedAtLeastOnce = useRef<boolean>(false);
  const tableRef = useRef<HTMLDivElement>(null);
  const isFirstRender = useRef(true);
  const apiRef = useGridApiRef();

  const { handleColumnResizingStop, handleColumnResize } = useTableColumnManipulation();
  const { handleRowSettingsPanel } = useTableRowSettingsPanel();
  const { applyCustomRulesToColumns } = useApplyRulesToColumn(sectionId, blockId);
  const blockContentChangedHandler = useBlockContentChangedHandler();
  const blockDimensionChangedHandlerWithoutUndoRedo = useBlockDimensionChangedHandlerWithoutUndoRedo();
  const { getTableData, getTableUpdatedDimensions, updateTableBlockDimensionsStoreState } = useTableManipulation();

  const isEditState = selectedBlockIdByWrapper === blockId && isEditMode === true;

  const handleTableSizeChange = async () => {
    /* handleTableSizeChange is triggered more than one time by Mui Datagrid when the block is being rendered for the first time.
    The reason we are adding this check below, is to make sure we are only updating the state of block (store or socket)
    if the variable isEditMode was changed at least one time by a user action.
    That variable can be changed whenever the user tries to access the block and change its content. */

    const dimensions = getTableUpdatedDimensions(apiRef.current);
    if (!dimensions) return;

    const { width, height } = dimensions;

    if (wasEditModeChangedAtLeastOnce.current || isSelectModel) {
      await blockDimensionChangedHandlerWithoutUndoRedo(sectionId, blockId, {
        widthPx: width,
        heightPx: height,
      });
    } else {
      // if there is no content changes, we update the table dimensions store state to avoid unnecessary WS updates
      await updateTableBlockDimensionsStoreState(blockId, sectionId, { width, height });
    }
  };

  const handleColumnHeaderClick = (
    columnSelection: GridColumnHeaderParams,
    _mouseEvent: MuiEvent,
    tableCallbackDetails: GridCallbackDetails
  ) => {
    dispatch(setToggledDesignSettingModelType({ type: null }));
    if (columnSelection.field !== '__reorder__') {
      dispatch(setActiveTableSettingsPanel({ type: TableSettingsTypes.TABLE_COLUMNS }));
      setToggledTableSettingsPanel({
        tableApi: { selectedModel: columnSelection, tableCallbackDetails: tableCallbackDetails },
      });
    } else {
      setToggledTableSettingsPanel(null);
    }
  };

  const handleTableDefaultSettingsPanel = (e) => {
    if (!isEditMode) {
      setIsSelectModel(true);
      dispatch(setToggledDesignSettingModelType({ type: null }));
      dispatch(setActiveTableSettingsPanel({ type: TableSettingsTypes.TABLE_DEFAULTS }));
    } else {
      setIsSelectModel(false);
    }
    handleOnMouseDown(e);
  };

  const handleRowOrderChange = async (params: GridRowOrderChangeParams) => {
    if (!selectedBlockIdByWrapper) return;
    const tableData = getTableData(selectedBlockIdByWrapper, sectionId) as TableType;
    const { oldIndex, targetIndex } = params;
    const { rows } = tableData;
    const tableRows = [...rows];
    const element = tableRows.splice(oldIndex, 1)[0];
    tableRows.splice(targetIndex, 0, element);
    await blockContentChangedHandler(blockId, sectionId, { ...tableData, rows: tableRows });
  };

  const handleColumnOrderChange = async (params: GridColumnOrderChangeParams) => {
    if (!selectedBlockIdByWrapper) return;
    const tableData = getTableData(selectedBlockIdByWrapper, sectionId) as TableType;
    const { oldIndex, targetIndex } = params;
    const { columns } = tableData;
    const tableColumns = [...columns];
    const element = tableColumns.splice(oldIndex - 1, 1)[0];
    tableColumns.splice(targetIndex - 1, 0, element);
    await blockContentChangedHandler(blockId, sectionId, { ...tableData, columns: tableColumns });
  };

  useEffect(() => {
    if (apiRef?.current) {
      return apiRef.current.subscribeEvent('cellKeyUp', onCellEdited);
    }
  }, [apiRef?.current]);

  useEffect(() => {
    if (isFirstRender.current === false) {
      wasEditModeChangedAtLeastOnce.current = true;
    }
  }, [isEditMode]);

  useEffect(() => {
    isFirstRender.current = false;
  }, []);

  useEffect(() => {
    const handleRowDragEnd: GridEventListener<'rowDragEnd'> = () => {
      if (setIsDragManagerSetup) {
        setIsDragManagerSetup(true);
        dragDropManager.getBackend().setup();
      }
    };

    const handleRowDragStart: GridEventListener<'rowDragStart'> = () => {
      if (setIsDragManagerSetup) {
        dragDropManager.getBackend().teardown();
        setIsDragManagerSetup(false);
      }
    };

    let unsubscribeRowDragEnd: (() => void) | undefined;
    let unsubscribeRowDragStart: (() => void) | undefined;

    if (apiRef?.current) {
      unsubscribeRowDragEnd = apiRef.current.subscribeEvent('rowDragEnd', handleRowDragEnd);
      unsubscribeRowDragStart = apiRef.current.subscribeEvent('rowDragStart', handleRowDragStart);
    }

    return () => {
      if (typeof unsubscribeRowDragEnd === 'function') {
        unsubscribeRowDragEnd();
      }
      if (typeof unsubscribeRowDragStart === 'function') {
        unsubscribeRowDragStart();
      }
    };
  }, [apiRef]);

  const { totalRows } = getPricingTotalsAndTextRowsSegregated(allTextTableRows);
  /* After all calculations logics are moved to the footer component, calculateTotals should only receive textRows,
   instead of allTextTableRows. Calculate Total will then be only responsible for calculating values of the subtotal column
   and not any values for the footer rows. example below:

   const { textRows, totalRows } = getPricingTotalsAndTextRowsSegregated(allTextTableRows);
   const { textRowsWithCalculatedSubtotalsColumn, sumOfSubtotalColumn } = calculateTotal(allTextTableRows, allTextTableColumns);
   */
  const { textRowsWithCalculatedSubtotalsColumn, sumOfSubtotalColumn } = calculateTotal(allTextTableRows, allTextTableColumns);

  return (
    <DraggableGridBlockWrapper
      key={`grid-text-table-${blockId}`}
      blockId={blockId}
      isEditMode={isEditState}
      onMouseDown={(e) => handleTableDefaultSettingsPanel(e)}
      disableResizingHandlers
    >
      <Box height={'100%'}>
        <DataGridPro
          ref={tableRef}
          apiRef={apiRef}
          rows={textRowsWithCalculatedSubtotalsColumn}
          columns={applyCustomRulesToColumns(allTextTableColumns)}
          loading={loading}
          data-testid={testId}
          rowReordering={isEditState}
          onColumnResize={(gridColumnResizeParams) => handleColumnResize(blockId, gridColumnResizeParams)}
          onColumnWidthChange={() => handleColumnResizingStop(blockId, apiRef.current)}
          onCellClick={(cellSelection, _mouseEvent, tableCallbackDetails) => handleRowSettingsPanel(cellSelection, tableCallbackDetails)}
          onColumnHeaderClick={handleColumnHeaderClick}
          className={isEditState ? 'py-contentful-state' : 'py-selected-state'}
          onResize={handleTableSizeChange}
          onRowOrderChange={handleRowOrderChange}
          disableColumnReorder={true}
          onColumnOrderChange={handleColumnOrderChange}
          getRowHeight={() => 'auto'}
          getCellClassName={getCellClassName}
          getRowClassName={getRowClassName}
          slots={{
            footer: () => (
              <PricingTableTotalsFooter
                tableApi={apiRef.current}
                isEditState={isEditState}
                totalRows={totalRows}
                sumOfSubtotalColumn={sumOfSubtotalColumn}
              />
            ),
          }}
        />
      </Box>
    </DraggableGridBlockWrapper>
  );
}
