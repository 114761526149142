import { GridColDef, GridValidRowModel } from '@mui/x-data-grid-pro';
import { GridBlockType } from 'components/editor/shared/gridBlockType';
import { GridDeleteType, GridPositionChangedType, GridLayerChangedType, GridDimensionChangedType } from './saveHandlers';

export enum TableTypeIdentifier {
  TEXT_TABLE = 'text_table',
  PRICING_TABLE = 'pricing_table',
}
export enum ColumnTypeIdentifier {
  SUBTOTAL = 'Subtotal',
  PRICE = 'Price',
  MULTIPLIER = 'Multiplier',
  TEXT = 'Text',
  HANDLER = 'Handler',
  OPTIONAL = 'Optional',
}

export enum TotalRowTypesIdentifier {
  SUBTOTAL = 'Subtotal',
  DISCOUNT = 'Discount',
  TAX = 'Tax',
  TOTAL = 'Total',
}

export enum RowTypesIdentifier {
  HEADER = 'Header',
  BODY = 'Body',
  FOOTER = 'Footer',
}

type AllRowTypeIdentifier = TotalRowTypesIdentifier | RowTypesIdentifier;

type TableMetadataType = {
  tableType: TableTypeIdentifier;
  pricingDecimalPlaces: number;
};

export interface TableRowType extends GridValidRowModel {
  cellConfig?: {
    [key: string]:
      | Record<string, boolean | string | number>
      | {
          valueFormater?: string;
          hideCell?: boolean;
        };
  };
  rowType: AllRowTypeIdentifier;
  id: string;
  __reorder__: string;
}

export type TableColumnType = GridColDef & { columnType: ColumnTypeIdentifier; renderCell?: any; valueOptions?: string[] };

export type TableType = {
  rows: TableRowType[];
  columns: TableColumnType[];
  metadata: TableMetadataType;
};

export type TableContentType = TableType;

export type SaveTableContentType = TableContentType & GridDeleteType;

export type GridAddTableType = {
  type: GridBlockType;
  content: TableContentType;
  sectionId: string;
} & GridPositionChangedType &
  GridLayerChangedType &
  GridDimensionChangedType;
