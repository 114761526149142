import { KeyboardEvent, useContext, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  DataGridPro,
  GridCallbackDetails,
  GridCellParams,
  MuiEvent,
  GridColumnHeaderParams,
  useGridApiRef,
  GridRowOrderChangeParams,
  GridColumnOrderChangeParams,
  GridEventListener,
} from '@mui/x-data-grid-pro';
import { Box } from '@mui/material';

import { DraggableGridBlockWrapper } from '../../DraggableGridBlock/DraggableGridBlockWrapper';
import useBlockState from '../useBlockState';
import { SidePanelProviderContext } from 'components/editor/SidePanel/content/SidePanelModelsProvider';
import { SelectionContext } from '../../SelectedBlockInfoProvider';

import { useApplyRulesToColumn } from './useApplyRulesToColumn';
import { useTableColumnManipulation } from '../../../SidePanel/content/TableSettings/ColumnSettings/useTableColumnManipulation';
import { RootState, useAppDispatch } from '../../../grid/reduxStore/Store';
import { selectContentTableRows, selectContentTableColumns } from '../../../grid/reduxStore/editorSlice';
import { setToggledDesignSettingModelType } from '../../../grid/reduxStore/blockStyleSettingsSlice';
import { useBlockContentChangedHandler } from '../../../hooks/UseBlockContentChangedHandler';
import { useTableManipulation } from './useTableManipulation';
import { useBlockDimensionChangedHandlerWithoutUndoRedo } from '../../../hooks/UseBlockDimensionChangedHandler';
import { dragDropManager } from '../../../dndContext';
import { cellFocusOutEventKeys } from './variables';
import { TableType } from '../../../grid/reduxStore/table.types';
import { TableRowTypes } from '../../../../../muiTheme/dataGridUtils';
import { getSanitizedDataForTableFooter } from '../../../../../utils/getSanitizedDataForTableFooter';
import { useSectionData } from '../../../Sections/useSectionContext';
import { setActiveTableSettingsPanel } from '../../../grid/reduxStore/blockStyleSettingsSlice';
import { TableSettingsTypes } from '../../../SidePanel/content/TableSettings/types';
interface TextTableBlockProps {
  loading: boolean;
  documentId: string;
  blockId: string;
  handleMouseDown?: (blockId) => void;
  setIsDragManagerSetup?: (state: boolean) => void;
  testId?: string;
}

export function TextTableBlockDeprecated({ loading, blockId, setIsDragManagerSetup, testId }: TextTableBlockProps) {
  const { handleOnMouseDown, isEditMode } = useBlockState(blockId);
  const dispatch = useAppDispatch();
  const { activeTableSettingsPanel } = useSelector((state: RootState) => state.blockStyleSettings);
  const wasEditModeChangedAtLeastOnce = useRef<boolean>(false);
  const tableRef = useRef<HTMLDivElement>(null);
  const isFirstRender = useRef(true);
  const { toggledTableSettingsPanel, setToggledTableSettingsPanel } = useContext(SidePanelProviderContext);
  const { selectedBlockIdByWrapper } = useContext(SelectionContext);
  const { handleColumnResizingStop, handleColumnResize } = useTableColumnManipulation();
  const { sectionId } = useSectionData();
  const { applyCustomRulesToColumns } = useApplyRulesToColumn(sectionId, blockId);
  const allTextTableRows = useSelector((state: RootState) => selectContentTableRows(state, sectionId, blockId));
  const apiRef = useGridApiRef();
  const allTextTableColumns = useSelector((state: RootState) => selectContentTableColumns(state, sectionId, blockId)) || [];
  const blockContentChangedHandler = useBlockContentChangedHandler();
  const blockDimensionChangedHandlerWithoutUndoRedo = useBlockDimensionChangedHandlerWithoutUndoRedo();

  const { getTableData, getTableUpdatedDimensions } = useTableManipulation();

  const isEditState = selectedBlockIdByWrapper === blockId && isEditMode === true;

  const getUpdatedRowsTableDataFromCellEdit = (cellParams: GridCellParams, newHTML: string) => {
    const tableData = getTableData(blockId, sectionId);
    if (!tableData) return;

    const updatedRows = tableData.rows.map((row) => {
      if (row.id === cellParams.id && row.rowType !== TableRowTypes().FOOTER) {
        return { ...row, [cellParams.field]: newHTML };
      } else if (row.id === cellParams.id && row.rowType === TableRowTypes().FOOTER) {
        const convertedRowToFooterData = getSanitizedDataForTableFooter(row, tableData.columns, newHTML);
        return { ...convertedRowToFooterData, rowType: TableRowTypes().FOOTER };
      } else {
        return { ...row };
      }
    });

    return { ...tableData, rows: updatedRows };
  };

  const onCellEdited = async (cellParams: GridCellParams, event: MuiEvent<KeyboardEvent>) => {
    if (cellFocusOutEventKeys.includes(event.key)) return;
    const cellTarget = event.target as HTMLInputElement;
    const updatedTableData = getUpdatedRowsTableDataFromCellEdit(cellParams, cellTarget.value);
    if (!updatedTableData) return;

    await blockContentChangedHandler(blockId, sectionId, updatedTableData);
  };

  const handleTableSizeChange = async () => {
    /* handleTableSizeChange is triggered more than one time by Mui Datagrid when the block is being rendered for the first time.
    The reason we are adding this check below, is to make sure we are only updating the state of block (store or socket)
    if the variable isEditMode was changed at least one time by a user action.
    That variable can be changed whenever the user tries to access the block and change its content. */
    if (wasEditModeChangedAtLeastOnce.current === true) {
      const dimensions = getTableUpdatedDimensions(apiRef.current);
      if (!dimensions) return;
      await blockDimensionChangedHandlerWithoutUndoRedo(sectionId, blockId, {
        widthPx: dimensions.width,
        heightPx: dimensions.height,
      });
    }
  };

  const handleRowSettingsPanel = (cellSelection: GridCellParams, _mouseEvent: MuiEvent, tableCallbackDetails: GridCallbackDetails) => {
    dispatch(setToggledDesignSettingModelType({ type: null }));
    dispatch(setActiveTableSettingsPanel({ type: TableSettingsTypes.TABLE_ROWS }));
    setToggledTableSettingsPanel({
      tableApi: { selectedModel: cellSelection, tableCallbackDetails: tableCallbackDetails },
    });
  };

  const handleColumnHeaderClick = (
    columnSelection: GridColumnHeaderParams,
    _mouseEvent: MuiEvent,
    tableCallbackDetails: GridCallbackDetails
  ) => {
    dispatch(setToggledDesignSettingModelType({ type: null }));
    if (columnSelection.field !== '__reorder__') {
      dispatch(setActiveTableSettingsPanel({ type: TableSettingsTypes.TABLE_COLUMNS }));
      setToggledTableSettingsPanel({
        tableApi: { selectedModel: columnSelection, tableCallbackDetails: tableCallbackDetails },
      });
    } else {
      setToggledTableSettingsPanel(null);
    }
  };

  function getCellClassName(params: GridCellParams) {
    const isColumnSelected = activeTableSettingsPanel && toggledTableSettingsPanel?.tableApi.selectedModel.field === params.field;

    if (isColumnSelected) {
      return 'py-selected-column-cell';
    }
    return '';
  }

  const handleRowOrderChange = async (params: GridRowOrderChangeParams) => {
    if (!selectedBlockIdByWrapper) return;
    const tableData = getTableData(selectedBlockIdByWrapper, sectionId) as TableType;
    const { oldIndex, targetIndex } = params;
    const { rows } = tableData;
    const tableRows = [...rows];
    const element = tableRows.splice(oldIndex, 1)[0];
    tableRows.splice(targetIndex, 0, element);
    await blockContentChangedHandler(blockId, sectionId, { ...tableData, rows: tableRows });
  };

  const handleColumnOrderChange = async (params: GridColumnOrderChangeParams) => {
    if (!selectedBlockIdByWrapper) return;
    const tableData = getTableData(selectedBlockIdByWrapper, sectionId) as TableType;
    const { oldIndex, targetIndex } = params;
    const { columns } = tableData;
    const tableColumns = [...columns];
    const element = tableColumns.splice(oldIndex - 1, 1)[0];
    tableColumns.splice(targetIndex - 1, 0, element);
    await blockContentChangedHandler(blockId, sectionId, { ...tableData, columns: tableColumns });
  };

  useEffect(() => {
    return apiRef.current.subscribeEvent('cellKeyUp', onCellEdited);
  }, [apiRef.current]);

  useEffect(() => {
    if (isFirstRender.current === false) {
      wasEditModeChangedAtLeastOnce.current = true;
    }
  }, [isEditMode]);

  useEffect(() => {
    isFirstRender.current = false;
  }, []);

  useEffect(() => {
    const handleRowDragEnd: GridEventListener<'rowDragEnd'> = () => {
      if (setIsDragManagerSetup) {
        setIsDragManagerSetup(true);
        dragDropManager.getBackend().setup();
      }
    };

    const handleRowDragStart: GridEventListener<'rowDragStart'> = () => {
      if (setIsDragManagerSetup) {
        dragDropManager.getBackend().teardown();
        setIsDragManagerSetup(false);
      }
    };

    const unsubscribeRowDragEnd = apiRef.current.subscribeEvent('rowDragEnd', handleRowDragEnd);
    const unsubscribeRowDragStart = apiRef.current.subscribeEvent('rowDragStart', handleRowDragStart);

    return () => {
      unsubscribeRowDragEnd();
      unsubscribeRowDragStart();
    };
  }, [apiRef]);

  return (
    <DraggableGridBlockWrapper
      key={`grid-text-table-${blockId}`}
      blockId={blockId}
      isEditMode={isEditState}
      onMouseDown={(e) => handleOnMouseDown(e)}
      disableResizingHandlers
    >
      <Box>
        <DataGridPro
          ref={tableRef}
          apiRef={apiRef}
          rows={allTextTableRows}
          columns={applyCustomRulesToColumns(allTextTableColumns)}
          loading={loading}
          data-testid={testId}
          rowReordering={isEditState}
          onColumnResize={(gridColumnResizeParams) => handleColumnResize(blockId, gridColumnResizeParams)}
          onColumnWidthChange={() => handleColumnResizingStop(blockId, apiRef.current)}
          onCellClick={handleRowSettingsPanel}
          onColumnHeaderClick={handleColumnHeaderClick}
          className={isEditState ? 'py-contentful-state' : 'py-selected-state'}
          getCellClassName={(params) => getCellClassName(params)}
          onResize={handleTableSizeChange}
          onRowOrderChange={handleRowOrderChange}
          disableColumnReorder={true}
          onColumnOrderChange={handleColumnOrderChange}
        />
      </Box>
    </DraggableGridBlockWrapper>
  );
}
