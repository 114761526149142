import { RowTypesIdentifier, TableRowType, TotalRowTypesIdentifier } from '../components/editor/grid/reduxStore/table.types';

const allTableRowTypes = {
  ...RowTypesIdentifier,
  ...TotalRowTypesIdentifier,
};
export const TableRowTypes = (isPricing?: boolean) => (!isPricing ? RowTypesIdentifier : allTableRowTypes);
export const isRowBelongsToTotal = (row) => Object.values(TotalRowTypesIdentifier).includes(row.rowType);
export const getRowClassName = (params) => {
  if (params.row.rowType === TableRowTypes().HEADER) return 'py-header-row';
  if (params.row.rowType === TableRowTypes().FOOTER) return 'py-footer-row';
  if (isRowBelongsToTotal(params.row)) return `py-total-row ${params.row?.isRowHidden ? 'py-hide-row' : ''}`;
  else return '';
};

export const isTaxOrDiscountRow = (row: TableRowType) =>
  row.rowType === TotalRowTypesIdentifier.TAX || row.rowType === TotalRowTypesIdentifier.DISCOUNT;
