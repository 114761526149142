import { v4 as uuidv4 } from 'uuid';
import { defaultRowMeta, defaultColumnMeta, initialColumnWidth, initialPricingColumnWidth } from './variables';
import {
  TableTypeIdentifier,
  TableColumnType,
  ColumnTypeIdentifier,
  TotalRowTypesIdentifier,
  RowTypesIdentifier,
} from 'components/editor/grid/reduxStore/table.types';
import { DEFAULT_DECIMAL_PLACES } from 'components/editor/SidePanel/content/TableSettings/TableDefaultSettings';

export const cellMenu = [
  { sign: '$', value: 'document.grid.table.formater_flat' },
  { sign: '%', value: 'document.grid.table.formater_percent' },
  { sign: 'Hide cell', value: 'document.grid.table.hide_cell' },
];

export const optionalColumn = {
  field: 'optionalColumn',
  headerName: '',
  width: 60,
  editable: false,
  columnType: ColumnTypeIdentifier.OPTIONAL,
  type: 'string',
} as TableColumnType;

export const columns: TableColumnType[] = [
  { field: 'id', headerName: 'ID', width: 90, editable: false, columnType: ColumnTypeIdentifier.HANDLER },
  {
    ...defaultColumnMeta,
    field: 'column1',
    width: initialColumnWidth,
  },
  {
    ...defaultColumnMeta,
    field: 'column2',
    width: initialColumnWidth,
  },
];

export const rows = [
  { id: uuidv4(), column1: 'Text', column2: 'Text', rowType: RowTypesIdentifier.HEADER, ...defaultRowMeta },
  { id: uuidv4(), column1: 'Enter text here...', column2: 'Or add a new row...', rowType: RowTypesIdentifier.BODY, ...defaultRowMeta },
  {
    id: uuidv4(),
    column1: 'Maybe change the row type!',
    column2: 'Or add another column!',
    rowType: RowTypesIdentifier.BODY,
    ...defaultRowMeta,
  },
];

export const pricingColumns: TableColumnType[] = [
  { field: 'id', headerName: 'ID', width: 90, editable: false, columnType: ColumnTypeIdentifier.HANDLER },
  {
    field: 'column1',
    headerName: 'Name',
    width: initialPricingColumnWidth,
    editable: true,
    columnType: ColumnTypeIdentifier.TEXT,
    type: 'string',
  },
  {
    field: 'column2',
    headerName: 'Multiplier',
    width: initialPricingColumnWidth,
    headerAlign: 'right',
    align: 'right',
    editable: true,
    columnType: ColumnTypeIdentifier.MULTIPLIER,
    type: 'number',
  },
  {
    field: 'price',
    headerName: 'Price',
    width: initialPricingColumnWidth,
    headerAlign: 'right',
    align: 'right',
    editable: true,
    columnType: ColumnTypeIdentifier.PRICE,
    type: 'number',
  },
  {
    field: 'subtotal',
    headerName: 'Subtotal',
    width: initialPricingColumnWidth,
    headerAlign: 'right',
    align: 'right',
    editable: true,
    columnType: ColumnTypeIdentifier.SUBTOTAL,
    type: 'number',
  },
];

export const setOfRowsForPricingTable = [
  {
    id: uuidv4(),
    column1: 'Subtotal',
    rowType: TotalRowTypesIdentifier.SUBTOTAL,
    isRowHidden: false,
    ...defaultRowMeta,
  },
  {
    id: uuidv4(),
    column1: 'Tax',
    rowType: TotalRowTypesIdentifier.TAX,
    cellConfig: {
      price: {
        valueFormater: '%',
      },
    },
    optionsMenu: cellMenu,
    ...defaultRowMeta,
  },
  {
    id: uuidv4(),
    column1: 'Discount',
    rowType: TotalRowTypesIdentifier.DISCOUNT,
    cellConfig: {
      price: {
        valueFormater: '$',
      },
    },
    optionsMenu: cellMenu,
    ...defaultRowMeta,
  },
  {
    id: uuidv4(),
    column1: 'Totals',
    rowType: TotalRowTypesIdentifier.TOTAL,
    ...defaultRowMeta,
  },
];

export const pricingRows = [
  {
    id: uuidv4(),
    column1: 'Name',
    column2: 'Quantity',
    price: 'Price',
    subtotal: 'Subtotal',
    rowType: RowTypesIdentifier.HEADER,
    isRowOptional: false,
    ...defaultRowMeta,
  },
  {
    id: uuidv4(),
    column1: '',
    column2: '',
    price: '',
    subtotal: '',
    rowType: RowTypesIdentifier.BODY,
    isRowOptional: false,
    ...defaultRowMeta,
  },
  {
    id: uuidv4(),
    column1: '',
    column2: '',
    price: '',
    subtotal: '',
    rowType: RowTypesIdentifier.BODY,
    isRowOptional: false,
    ...defaultRowMeta,
  },
  {
    id: uuidv4(),
    column1: '',
    column2: '',
    price: '',
    subtotal: '',
    rowType: RowTypesIdentifier.BODY,
    isRowOptional: false,
    ...defaultRowMeta,
  },
  {
    id: uuidv4(),
    column1: '',
    column2: '',
    price: '',
    subtotal: '',
    rowType: RowTypesIdentifier.BODY,
    isRowOptional: false,
    ...defaultRowMeta,
  },
  ...setOfRowsForPricingTable,
];

export const defaultTableContent = {
  rows,
  columns,
  metadata: {
    tableType: TableTypeIdentifier.TEXT_TABLE,
    pricingDecimalPlaces: DEFAULT_DECIMAL_PLACES,
  },
};

export const defaultPricingTableContent = {
  rows: pricingRows,
  columns: pricingColumns,
  metadata: {
    tableType: TableTypeIdentifier.PRICING_TABLE,
    pricingDecimalPlaces: DEFAULT_DECIMAL_PLACES,
  },
};
