import { Box, Typography } from '@mui/material';
import { MouseEventHandler } from 'react';

export const PricingTableTotalFooterRow = ({
  title,
  value,
  onClick,
  isEditable,
}: {
  title: string;
  value: string;
  onClick?: MouseEventHandler;
  isEditable: boolean;
}) => {
  return (
    <Box
      className="py-total-row"
      sx={{
        display: 'flex',
        justifyContent: 'space-evenly',
        padding: 2,
        borderTop: '1px solid lightgray',
        backgroundColor: '#00658C',
        fontWeight: 600,
        color: '#ffffff',
        lineHeight: 'inherit',
      }}
      onClick={onClick}
    >
      <div
        style={{
          display: 'table-column',
          width: '33%',
          textAlign: 'right',
        }}
      >
        <Typography variant="body1">{title}</Typography>
      </div>
      <div
        style={{
          display: 'table-column',
          width: '33%',
          textAlign: 'right',
        }}
      >
        {isEditable && (
          <span className="py-custom-cell-wrapper">
            <input data-testid={`${title}-input-field`} className={'py-number-input-no-arrow'} type={'number'} />
          </span>
        )}
      </div>
      <div
        style={{
          display: 'table-column',
          width: '33%',
          textAlign: 'right',
        }}
      >
        <Typography variant="body1">{value}</Typography>
      </div>
    </Box>
  );
};
