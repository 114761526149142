import { ColumnTypeIdentifier, TableRowType, TotalRowTypesIdentifier } from '../../../grid/reduxStore/table.types';
import { MouseEventHandler, MouseEvent } from 'react';
import { GridApiPro } from '@mui/x-data-grid-pro';
import { PricingTableTotalFooterRow } from './PricingTableTotalFooterRow';

type PricingTableTotalsFooterType = { tableApi: GridApiPro; isEditState: boolean; totalRows: TableRowType[]; sumOfSubtotalColumn: number };
export const PricingTableTotalsFooter = ({ tableApi, isEditState, totalRows, sumOfSubtotalColumn }: PricingTableTotalsFooterType) => {
  return (
    <>
      {totalRows.map((row) => {
        let value = '';
        const isEditable = [TotalRowTypesIdentifier.TAX, TotalRowTypesIdentifier.DISCOUNT].includes(row.rowType as TotalRowTypesIdentifier);

        if (row.rowType === TotalRowTypesIdentifier.SUBTOTAL) {
          value = sumOfSubtotalColumn.toString();
        }

        const handleOnClick: MouseEventHandler = (mouseEvent: MouseEvent<HTMLElement>) => {
          if (!isEditState) return;
          const cellParams = tableApi.getCellParams(row.id, ColumnTypeIdentifier.PRICE);
          tableApi.publishEvent('cellClick', cellParams, mouseEvent);
        };

        return (
          <PricingTableTotalFooterRow key={row.id} onClick={handleOnClick} title={row.rowType} isEditable={isEditable} value={value} />
        );
      })}
    </>
  );
};
