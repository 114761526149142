import { GridValidRowModel } from '@mui/x-data-grid-pro';
import {
  ColumnTypeIdentifier,
  RowTypesIdentifier,
  TableColumnType,
  TableRowType,
  TotalRowTypesIdentifier,
} from '../../../../grid/reduxStore/table.types';

const DEFAULT_PRICING_TABLE_TOTAL_PRECISION = 5;
function segregateColumns(columns: TableColumnType[]) {
  return columns.reduce((acc, column) => {
    const { columnType } = column;

    if (!acc[columnType]) {
      acc[columnType] = [];
    }

    acc[columnType].push(column);
    return acc;
  }, {} as Record<ColumnTypeIdentifier, TableColumnType[]>);
}

function calculateSubTotalsPerRow(
  segregatedCols: Record<ColumnTypeIdentifier, TableColumnType[]>,
  textRows: TableRowType[],
  precision = DEFAULT_PRICING_TABLE_TOTAL_PRECISION
): { textRowsWithCalculatedSubtotalsColumn: TableRowType[]; sumOfSubtotalColumn: number } {
  const priceColumn = segregatedCols[ColumnTypeIdentifier.PRICE]?.[0];
  const subtotalColumn = segregatedCols[ColumnTypeIdentifier.SUBTOTAL]?.[0];
  const multiplierColumns = segregatedCols[ColumnTypeIdentifier.MULTIPLIER] || [];
  let sumOfSubtotalColumn = 0;

  if (!priceColumn || !subtotalColumn) {
    return { textRowsWithCalculatedSubtotalsColumn: textRows, sumOfSubtotalColumn };
  }

  const textRowsWithCalculatedSubtotalsColumn = textRows.map((row) => {
    const priceValueStr = row[priceColumn.field];
    const rowTypeIsBody = row.rowType === RowTypesIdentifier.BODY;

    if (!rowTypeIsBody || !priceValueStr) return row;

    let priceValue = parseFloat(priceValueStr);
    if (isNaN(priceValue)) return row;

    multiplierColumns.forEach((multiplierCol) => {
      const multiplierValueStr = row[multiplierCol.field];
      const multiplierValue = parseFloat(multiplierValueStr) || 1;
      priceValue *= multiplierValue;
    });

    const roundedSubtotal = Number(priceValue.toFixed(precision));
    sumOfSubtotalColumn += roundedSubtotal;

    return {
      ...row,
      [subtotalColumn.field]: roundedSubtotal,
    };
  });

  return { textRowsWithCalculatedSubtotalsColumn, sumOfSubtotalColumn };
}

function calculateSubtotalFooterRow(
  rows: TableRowType[],
  subtotalColumn: TableColumnType,
  precision = DEFAULT_PRICING_TABLE_TOTAL_PRECISION
): TableRowType[] {
  const subtotal = rows.reduce((acc, row) => {
    if (row.rowType === RowTypesIdentifier.BODY && !row?.isRowOptional) {
      const rowSubtotal = parseFloat(row[subtotalColumn.field]) || 0;
      acc += rowSubtotal;
    }
    return acc;
  }, 0);

  const roundedSubtotal = Number(subtotal.toFixed(precision));

  const subtotalRow = rows.find((row) => row.rowType === TotalRowTypesIdentifier.SUBTOTAL);

  if (subtotalRow) {
    const updatedSubtotalRow = { ...subtotalRow, [subtotalColumn.field]: roundedSubtotal };

    return rows.map((row) => (row === subtotalRow ? updatedSubtotalRow : row));
  }

  return rows;
}

function segregateRows(rows: TableRowType[]): Record<RowTypesIdentifier, TableRowType[]> {
  return rows.reduce((acc: Record<RowTypesIdentifier, TableRowType[]>, row) => {
    const { rowType } = row;

    if (!acc[rowType]) {
      acc[rowType] = [];
    }

    acc[rowType].push(row);
    return acc;
  }, {} as Record<RowTypesIdentifier, TableRowType[]>);
}

function roundToPrecision(value: number, precision = DEFAULT_PRICING_TABLE_TOTAL_PRECISION): number {
  return Number(value.toFixed(precision));
}

function calculateAdjustment(
  segRows: Record<RowTypesIdentifier, TableRowType[]>,
  segregatedCols: Record<ColumnTypeIdentifier, TableColumnType[]>,
  type: TotalRowTypesIdentifier,
  precision = DEFAULT_PRICING_TABLE_TOTAL_PRECISION
): Record<RowTypesIdentifier, TableRowType[]> {
  const rows = segRows[type];
  const targetRow = segRows[TotalRowTypesIdentifier.SUBTOTAL]?.[0];
  const subtotalColumn = segregatedCols[ColumnTypeIdentifier.SUBTOTAL]?.[0];
  const pricingColumn = segregatedCols[ColumnTypeIdentifier.PRICE]?.[0];

  if (!rows || !targetRow || !subtotalColumn || !pricingColumn) return segRows;

  segRows[type] = rows.map((row: GridValidRowModel) => {
    const adjustment: number =
      row.cellConfig?.price?.valueFormater === '%'
        ? (parseFloat(row[pricingColumn.field]) * parseFloat(targetRow[subtotalColumn.field])) / 100
        : parseFloat(row[pricingColumn.field]) ?? 0;

    return { ...row, [subtotalColumn.field]: roundToPrecision(adjustment, precision) };
  });

  return segRows;
}

export function calculateTotal(
  rows: TableRowType[],
  columns: TableColumnType[]
): { textRowsWithCalculatedSubtotalsColumn: TableRowType[]; sumOfSubtotalColumn: number } {
  const segregatedCols = segregateColumns(columns);
  const subtotalColumn = segregatedCols[ColumnTypeIdentifier.SUBTOTAL]?.[0];

  const calculatedSubtotalRows = calculateSubTotalsPerRow(segregatedCols, rows);

  if (!subtotalColumn) {
    return calculatedSubtotalRows;
  }

  const rowsWithSubtotalRowCalculated = calculateSubtotalFooterRow(
    calculatedSubtotalRows.textRowsWithCalculatedSubtotalsColumn,
    segregatedCols[ColumnTypeIdentifier.SUBTOTAL]?.[0]
  );

  const segregatedRows = segregateRows(rowsWithSubtotalRowCalculated);
  const discountedRows = calculateAdjustment(segregatedRows, segregatedCols, TotalRowTypesIdentifier.DISCOUNT);
  const taxedRows = calculateAdjustment(discountedRows, segregatedCols, TotalRowTypesIdentifier.TAX);
  return {
    textRowsWithCalculatedSubtotalsColumn: Object.values(taxedRows).flat(),
    sumOfSubtotalColumn: calculatedSubtotalRows.sumOfSubtotalColumn,
  };
}
